@import "./styles/reset.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

/* stylelint-disable */
*,
*:before,
*:after {
  box-sizing: inherit;
}
/* stylelint-enable */

body {
  font-size: 1.6rem;
  font-family: 'Roboto', sans-serif;
  background: url(/assets/img/back.jpg);
  line-height: 1.4;
}
