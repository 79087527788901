@import url('https://fonts.googleapis.com/css2?family=Syne:wght@700&display=swap');

.title {
  font-size: 2rem;
  font-family: 'syne', sans-serif;
  text-align: center;
  background-image: linear-gradient(120deg,#FBCA61,#FBCA61);
  background-repeat: no-repeat;
  background-position: 0 90%;
  background-size: 100% .3em;
  margin-bottom: 2rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.head__img {
  width: 92vw;
  height: auto;
}

.button {
  text-decoration-color: #FBCA61;
  color: black;
  font-family: 'syne';
  font-size: 2rem;
  transition: text-decoration-color .3s;
  margin: 1rem;
}

.video {
  width: 92vw;
  height: auto;
}

.button:hover {
  text-decoration-color: black;
}

.icon__img {
  margin: 1rem;
}

.details {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1rem 0rem;
}

.icon__span {
  font-weight: 900;
}

.wrap__load {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading {
  font-size: 2rem;
  font-family: 'syne', sans-serif;
  text-align: center;
}

.img__wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.detail__img {
  width: 92vw;
  height: auto;
  margin: 1rem;
}

.circle {
  border: .4rem solid gainsboro;
  border-top: .4rem solid #FBCA61;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  animation: spin 2s linear infinite;
  margin: 2rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.post__des {
  max-width: 90rem;
  margin: 1rem 3rem;
  font-size: 1.6rem;
}

.detail__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem;
}

@media only screen and (min-width: 370px) {

  .title {
    font-size: 3rem;
  }
  
}


@media only screen and (min-width: 550px) {

  .img__wrap{
    flex-wrap: wrap;
    flex-direction: row;
  }

  .detail__img {
    width: 45vw;
    height: auto;
  }
  
}

@media only screen and (min-width: 730px) {

  .title {
    font-size: 4rem;
  }
  
}

@media only screen and (min-width: 900px) {

  
  
}
