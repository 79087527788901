@import url('https://fonts.googleapis.com/css2?family=Syne:wght@700&display=swap');

.title {
  font-size: 3rem;
  font-family: 'syne', sans-serif;
  text-align: center;
  background-image: linear-gradient(120deg,#FBCA61,#FBCA61);
  background-repeat: no-repeat;
  background-position: 0 90%;
  background-size: 100% .3em;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.background {
  background-color:#FBCA61 ;
  margin-top: 3rem;
}

.subtitle {
  font-size: 2rem;
  font-family: 'syne', sans-serif;
}

.socials {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mail {
  color: black;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.button__label {
  /* text-decoration: line-through solid #FBCA61; */
  font-size: 2.8rem;
  font-family: 'Syne', sans-serif;
  position: relative;
}

.button__label::after {
  border-bottom: 0.125em solid white;
  content: "";
  left: 0;
  margin-top: calc(0.125em / 2 * -1);
  position: absolute;
  right: 0;
  top: 50%;
  transition: 0.3s;
}

.button__label:hover::after {
  right: 100%
}

.button {
  text-decoration: underline transparent;
  color: black;
  text-align: center;
  margin: 4rem;
  display: flex;
}

.mailwrap {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}

.img {
  margin: 1rem;
}

@media only screen and (min-width: 730px) {

  .title {
    font-size: 4rem;
  }
  
}