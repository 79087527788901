@import url('https://fonts.googleapis.com/css2?family=Syne:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@value tokens: "../../styles/tokens.module.css";
@value beige, yellow from tokens;

.title {
  font-size: 2.8rem;
  background-image: linear-gradient(120deg,white,white);
  background-repeat: no-repeat;
  background-position: 0 90%;
  background-size: 100% .3em;
  font-family: 'Syne', sans-serif;
  text-align: center;
}

.subtitle {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  text-align: center;
  max-width: 40rem;
  margin: 1rem;
}

.title__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about {
  transform: rotate(-90deg);
  /* transform-origin: 0 0; */
  float: left;
}

.grid {
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr; */
  display: flex;
  flex-direction: column;
}

/* .list {
  display: flex;
  justify-content: space-between;
} */
.list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 4fr 1fr;
  height: 100vh;
}

.project {
  grid-column: 2;
  grid-row: 3;
  justify-self: center;
}

.title__wrap {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
}

.abouts {
  grid-column: 1;
  grid-row: 2;
  align-self: center;
}

.talks {
  grid-column: 3;
  grid-row: 2;
  align-self: center;
}

.plant__wrap {
  grid-column: 2;
  grid-row: 3;
}

.back__wrap {
  background: url(/assets/img/plantjes.png), #FBCA61;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 30rem;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.button__label {
  position: relative;
  font-size: 1.8rem;
  font-family: 'Syne', sans-serif;
}

.talk {
  transform: rotate(-90deg);
  /* transform-origin: 0 0; */
  float: right;
}

.project__label {
  
}

.button {
  text-decoration: underline transparent;
  color: black;
  text-align: center;
}

.button__label::after {
  border-bottom: 0.125em solid white;
  content: "";
  left: 0;
  margin-top: calc(0.125em / 2 * -1);
  position: absolute;
  right: 0;
  top: 50%;
  transition: 0.3s;
}

.button__label:hover::after {
  right: 100%
}

/* .button::before{
  content: url(/assets/img/pijlXSmall.png);
  margin-right: 1rem;
  transform: rotate(90deg);
  float:left;
} */

@media only screen and (min-width: 415px) {

  .button__label {
    font-size: 2.2rem;
  }

  /* .button::before{
    content: url(/assets/img/pijlXSmall.png);
    margin-right: 1rem;
    transform: rotate(90deg);
    float:left;
  } */

}

@media only screen and (min-width: 500px) {

  .button__label {
    font-size: 2.2rem;
  }

  /* .button::before{
    content: url(/assets/img/pijlSmall.png);
    margin-right: 1rem;
    transform: rotate(90deg);
    float:left;
  } */



}

@media only screen and (min-width: 790px) {

  .back__wrap {
    background: url(/assets/img/plantjes.png), url(/assets/img/geelVlak.png) ;
    background-repeat: no-repeat;
    background-position: top center, bottom center;
    background-size: 30rem, contain;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .button__label::after {
    border-bottom: 0.125em solid #FBCA61;
  }

  .list {
    grid-template-columns: 1fr 2fr 1fr;
  }

  .title {
    font-size: 3.8rem;
  }

  .subtitle {
    font-size: 1.9rem;
  }

  .button__label {
    font-size: 2.8rem;
  }

}

@media only screen and (min-width: 1100px) {
  

  .button__label {
    font-size: 3.4rem;
  }

  .back__wrap{
    background-size: 40rem, contain;
    }

    .subtitle {
      font-size: 2.6rem;
    }

    .title {
      font-size: 4.8rem;
    }

}

