@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Syne:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Syne:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Syne:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Syne:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Syne:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Syne:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Syne:wght@700&display=swap);
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  /* stylelint-disable */
  font: inherit;
  /* stylelint-enable */
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

/* stylelint-disable */
*,
*:before,
*:after {
  box-sizing: inherit;
}
/* stylelint-enable */

body {
  font-size: 1.6rem;
  font-family: 'Roboto', sans-serif;
  background: url(/assets/img/back.jpg);
  line-height: 1.4;
}

.NavBar_slider__1vrqY {
  font-family: 'syne', sans-serif;
  color: #989795;
  font-size: 1.3rem;
  float: left;
  width: 50%;
}

@-webkit-keyframes NavBar_marquee__2foyV {
  0% { left: 0; }
  100% { left: -100%; }
}

@keyframes NavBar_marquee__2foyV {
  0% { left: 0; }
  100% { left: -100%; }
}

.NavBar_slider__con__1HlM5 div {
  display: block;
  width: 200%;
  height: 30px;

  position: absolute;
  overflow: hidden;

  -webkit-animation: NavBar_marquee__2foyV 20s linear infinite;

          animation: NavBar_marquee__2foyV 20s linear infinite;
}

.NavBar_slider__con__1HlM5 {
  height: 20px;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

.NavBar_button__label__2zy-U {
  position: relative;
  font-size: 2.8rem;
  font-family: 'Syne', sans-serif;
}

.NavBar_button__28sYz {
  -webkit-text-decoration: underline transparent;
          text-decoration: underline transparent;
  color: black;
  text-align: center;
}

.NavBar_list__3gaWA {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 2rem 2rem;
}

.NavBar_link__2Iixg {
  margin: .5rem 0;
}

.NavBar_button__label__2zy-U::after {
  border-bottom: 0.125em solid #FBCA61;
  content: "";
  left: 0;
  margin-top: calc(0.125em / 2 * -1);
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.NavBar_button__label__2zy-U:hover::after {
  right: 100%
}

.NavBar_back__2xUqE {
  background-color: unset;
  border: unset;
  margin: 1rem 2rem;
}

.NavBar_navbar__yNL3i {
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-width: 370px) {

  .NavBar_slider__1vrqY {
    font-size: 1.8rem;
  }

  .NavBar_back__2xUqE {
    margin: 1rem 4rem;
  }

  .NavBar_list__3gaWA {
    margin: 2rem 4rem;
  }

  .NavBar_slider__con__1HlM5 {
    height: 25px;
  }
}

@media only screen and (min-width: 550px) {

  .NavBar_slider__1vrqY {
    font-size: 2.5rem;
  }

}


.Start_title__3rClM {
  font-size: 2.8rem;
  background-image: linear-gradient(120deg,white,white);
  background-repeat: no-repeat;
  background-position: 0 90%;
  background-size: 100% .3em;
  font-family: 'Syne', sans-serif;
  text-align: center;
}

.Start_subtitle__2gY3x {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  text-align: center;
  max-width: 40rem;
  margin: 1rem;
}

.Start_title__wrapper__1WG2o {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Start_about__3Faem {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  /* transform-origin: 0 0; */
  float: left;
}

.Start_grid__LGZQL {
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr; */
  display: flex;
  flex-direction: column;
}

/* .list {
  display: flex;
  justify-content: space-between;
} */
.Start_list__3TIfX {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 4fr 1fr;
  height: 100vh;
}

.Start_project__3sILu {
  grid-column: 2;
  grid-row: 3;
  justify-self: center;
}

.Start_title__wrap__JrGez {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
}

.Start_abouts__1YhET {
  grid-column: 1;
  grid-row: 2;
  align-self: center;
}

.Start_talks__DoNJ0 {
  grid-column: 3;
  grid-row: 2;
  align-self: center;
}

.Start_plant__wrap__2-YlI {
  grid-column: 2;
  grid-row: 3;
}

.Start_back__wrap__2oh2E {
  background: url(/assets/img/plantjes.png), #FBCA61;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 30rem;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.Start_button__label__1dXsu {
  position: relative;
  font-size: 1.8rem;
  font-family: 'Syne', sans-serif;
}

.Start_talk__2SqfC {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  /* transform-origin: 0 0; */
  float: right;
}

.Start_project__label__1qDb2 {
  
}

.Start_button__qACQM {
  -webkit-text-decoration: underline transparent;
          text-decoration: underline transparent;
  color: black;
  text-align: center;
}

.Start_button__label__1dXsu::after {
  border-bottom: 0.125em solid white;
  content: "";
  left: 0;
  margin-top: calc(0.125em / 2 * -1);
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.Start_button__label__1dXsu:hover::after {
  right: 100%
}

/* .button::before{
  content: url(/assets/img/pijlXSmall.png);
  margin-right: 1rem;
  transform: rotate(90deg);
  float:left;
} */

@media only screen and (min-width: 415px) {

  .Start_button__label__1dXsu {
    font-size: 2.2rem;
  }

  /* .button::before{
    content: url(/assets/img/pijlXSmall.png);
    margin-right: 1rem;
    transform: rotate(90deg);
    float:left;
  } */

}

@media only screen and (min-width: 500px) {

  .Start_button__label__1dXsu {
    font-size: 2.2rem;
  }

  /* .button::before{
    content: url(/assets/img/pijlSmall.png);
    margin-right: 1rem;
    transform: rotate(90deg);
    float:left;
  } */



}

@media only screen and (min-width: 790px) {

  .Start_back__wrap__2oh2E {
    background: url(/assets/img/plantjes.png), url(/assets/img/geelVlak.png) ;
    background-repeat: no-repeat;
    background-position: top center, bottom center;
    background-size: 30rem, contain;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .Start_button__label__1dXsu::after {
    border-bottom: 0.125em solid #FBCA61;
  }

  .Start_list__3TIfX {
    grid-template-columns: 1fr 2fr 1fr;
  }

  .Start_title__3rClM {
    font-size: 3.8rem;
  }

  .Start_subtitle__2gY3x {
    font-size: 1.9rem;
  }

  .Start_button__label__1dXsu {
    font-size: 2.8rem;
  }

}

@media only screen and (min-width: 1100px) {
  

  .Start_button__label__1dXsu {
    font-size: 3.4rem;
  }

  .Start_back__wrap__2oh2E{
    background-size: 40rem, contain;
    }

    .Start_subtitle__2gY3x {
      font-size: 2.6rem;
    }

    .Start_title__3rClM {
      font-size: 4.8rem;
    }

}


.Talk_title__3A5m0 {
  font-size: 3rem;
  font-family: 'syne', sans-serif;
  text-align: center;
  background-image: linear-gradient(120deg,#FBCA61,#FBCA61);
  background-repeat: no-repeat;
  background-position: 0 90%;
  background-size: 100% .3em;
}

.Talk_container__1ImsS {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Talk_subtitle__136nz {
  font-size: 2rem;
  font-family: 'syne', sans-serif;
}

.Talk_socials__3iFWi {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Talk_mail__3SMzp {
  color: black;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Talk_button__label__2Da0O::after {
  border-bottom: 0.125em solid #FBCA61;
  content: "";
  left: 0;
  margin-top: calc(0.125em / 2 * -1);
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.Talk_button__label__2Da0O:hover::after {
  right: 100%
}

.Talk_mailwrap__SXBW6 {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}

.Talk_img__1a45j {
  margin: 1rem;
}

@media only screen and (min-width: 730px) {

  .Talk_title__3A5m0 {
    font-size: 4rem;
  }
  
}
.Projects_title__FCsvv {
  font-size: 3rem;
  font-family: 'syne', sans-serif;
  text-align: center;
  background-image: linear-gradient(120deg,#FBCA61,#FBCA61);
  background-repeat: no-repeat;
  background-position: 0 90%;
  background-size: 100% .3em;
  margin-bottom: 2rem;
}

.Projects_container__23tIQ {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Projects_wrap__load__3EWZf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Projects_loading__1DwPb {
  font-size: 2rem;
  font-family: 'syne', sans-serif;
  text-align: center;
}

.Projects_circle__1ZOwg {
  border: .4rem solid gainsboro;
  border-top: .4rem solid #FBCA61;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  -webkit-animation: Projects_spin__3_wM8 2s linear infinite;
          animation: Projects_spin__3_wM8 2s linear infinite;
  margin: 2rem;
}

@-webkit-keyframes Projects_spin__3_wM8 {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes Projects_spin__3_wM8 {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.Projects_menu__3oIgJ {
  display: grid;
  grid-template-columns: 1fr;
}

.Projects_menu__link__3dyTd {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  text-decoration: none;
}

.Projects_menu__title__2AVoT {
  color: black;
  font-family: 'syne', sans-serif;
  font-size: 2rem;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  margin-top: 1rem;
  text-align: center;
}

.Projects_menu__tags__O1rp9 {
  color: black;
  font-family: 'syne', sans-serif;
  font-size: 1.4rem;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  margin-bottom: 2rem;
  text-align: center;
}

.Projects_menu__img__2-OHI {
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  height: auto;
  width: 92vw;
  grid-row: 1;
  grid-column: 1;
}

.Projects_p__2MiRZ {
  font-family: 'syne';
  font-size: 2rem;
  text-align: center;
  margin: 3rem;
}

.Projects_menu__item__tZUjh:hover .Projects_menu__title__2AVoT {
  opacity: 1;
}

.Projects_menu__item__tZUjh:hover .Projects_menu__tags__O1rp9 {
  opacity: 1;
}

.Projects_menu__item__tZUjh {
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 550px) {

  .Projects_menu__3oIgJ {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .Projects_menu__img__2-OHI {
    height: auto;
    width: 50vw;
  }
  
}

@media only screen and (min-width: 730px) {

  .Projects_title__FCsvv {
    font-size: 4rem;
  }
  
}

@media only screen and (min-width: 900px) {

  .Projects_menu__title__2AVoT {
  opacity: 0;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  margin: unset;
  grid-row: 1;
  grid-column: 1;
  align-self: center;
  justify-self: center;
}

.Projects_menu__tags__O1rp9 {
  opacity: 0;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  grid-row: 1;
  grid-column: 1;
  align-self: center;
  justify-self: center;
  margin-bottom: unset;
  margin-top: 4rem;
}
  
}

.About_title__1DXaD {
  font-size: 3rem;
  font-family: 'syne', sans-serif;
  text-align: center;
  background-image: linear-gradient(120deg,#FBCA61,#FBCA61);
  background-repeat: no-repeat;
  background-position: 0 90%;
  background-size: 100% .3em;
}

.About_about__text__3YWNf {
  font-size: 1.5rem;
  max-width: 65rem;
  margin: 2rem 2rem;
}

.About_qualilty_wrap__3WgHh {
  margin-top: 2rem;
  opacity: 0.4;
}

.About_light__B-Ldh {
  color: grey;
  text-align: center;
}

.About_button__devine__1is8x {
  font-size: 2.8rem;
  font-family: 'Syne', sans-serif;
  position: relative;
  -webkit-text-decoration: underline transparent;
          text-decoration: underline transparent;
  color: black;
  text-align: center;
  margin: 2rem;
  display: flex;
}

.About_button__devine__1is8x::after {
  border-bottom: 0.125em solid white;
  content: "";
  left: 0;
  margin-top: calc(0.125em / 2 * -1);
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.About_button__devine__1is8x:hover::after {
  right: 100%
}



.About_button__label__39xTX {
  /* text-decoration: line-through solid #FBCA61; */
  font-size: 4.8rem;
  font-family: 'Syne', sans-serif;
  position: relative;
}

.About_button__label__39xTX::after {
  border-bottom: 0.125em solid #FBCA61;
  content: "";
  left: 0;
  margin-top: calc(0.125em / 2 * -1);
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.About_button__label__39xTX:hover::after {
  right: 100%
}

.About_button__1Pqne {
  -webkit-text-decoration: underline transparent;
          text-decoration: underline transparent;
  color: black;
  text-align: center;
  margin: 4rem;
  display: flex;
}

.About_wrap__div__1IWxO {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 1rem;
  flex-direction: column;
}

.About_wrap__wrap__1xez5 {
  flex-direction: column-reverse;
}

.About_container__vm32Q {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* 
.button::before{
  content: url(/assets/img/pijlSmall.png);
  margin-right: 3rem;
  transform: rotate(90deg);
  float:left;
} */

.About_img__3y6-Q {
  margin: 0 2rem;
  width: 80vw;
  height: auto;
}

@media only screen and (min-width: 450px) {

  /* .img {
    width: unset;
    height: unset;
  } */
  
}

@media only screen and (min-width: 730px) {

  .About_wrap__div__1IWxO {
    flex-direction: row;
  }

  .About_title__1DXaD {
    font-size: 4rem;
  }

  .About_about__text__3YWNf {
    font-size: 2rem;
  }

  .About_img__3y6-Q {
    width: 31vw;
    height: auto;
  }

  .About_wrap__wrap__1xez5 {
    flex-direction: unset;
  }
  

}


.PostDetail_title__2ducY {
  font-size: 2rem;
  font-family: 'syne', sans-serif;
  text-align: center;
  background-image: linear-gradient(120deg,#FBCA61,#FBCA61);
  background-repeat: no-repeat;
  background-position: 0 90%;
  background-size: 100% .3em;
  margin-bottom: 2rem;
}

.PostDetail_container__1Uf4X {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PostDetail_head__img__2gM7t {
  width: 92vw;
  height: auto;
}

.PostDetail_button__6bFwu {
  -webkit-text-decoration-color: #FBCA61;
          text-decoration-color: #FBCA61;
  color: black;
  font-family: 'syne';
  font-size: 2rem;
  -webkit-transition: -webkit-text-decoration-color .3s;
  transition: -webkit-text-decoration-color .3s;
  transition: text-decoration-color .3s;
  transition: text-decoration-color .3s, -webkit-text-decoration-color .3s;
  margin: 1rem;
}

.PostDetail_video__1Klh4 {
  width: 92vw;
  height: auto;
}

.PostDetail_button__6bFwu:hover {
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
}

.PostDetail_icon__img__Hwl3H {
  margin: 1rem;
}

.PostDetail_details__3_1Zk {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1rem 0rem;
}

.PostDetail_icon__span__cHmdu {
  font-weight: 900;
}

.PostDetail_wrap__load__2u0H8 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.PostDetail_loading__24xFw {
  font-size: 2rem;
  font-family: 'syne', sans-serif;
  text-align: center;
}

.PostDetail_img__wrap__2appg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.PostDetail_detail__img__W5kGS {
  width: 92vw;
  height: auto;
  margin: 1rem;
}

.PostDetail_circle__34trx {
  border: .4rem solid gainsboro;
  border-top: .4rem solid #FBCA61;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  -webkit-animation: PostDetail_spin__ZOYtY 2s linear infinite;
          animation: PostDetail_spin__ZOYtY 2s linear infinite;
  margin: 2rem;
}

@-webkit-keyframes PostDetail_spin__ZOYtY {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes PostDetail_spin__ZOYtY {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.PostDetail_post__des__2aHtT {
  max-width: 90rem;
  margin: 1rem 3rem;
  font-size: 1.6rem;
}

.PostDetail_detail__item__2uncu {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem;
}

@media only screen and (min-width: 370px) {

  .PostDetail_title__2ducY {
    font-size: 3rem;
  }
  
}


@media only screen and (min-width: 550px) {

  .PostDetail_img__wrap__2appg{
    flex-wrap: wrap;
    flex-direction: row;
  }

  .PostDetail_detail__img__W5kGS {
    width: 45vw;
    height: auto;
  }
  
}

@media only screen and (min-width: 730px) {

  .PostDetail_title__2ducY {
    font-size: 4rem;
  }
  
}

@media only screen and (min-width: 900px) {

  
  
}

.Footer_title__3IB3- {
  font-size: 3rem;
  font-family: 'syne', sans-serif;
  text-align: center;
  background-image: linear-gradient(120deg,#FBCA61,#FBCA61);
  background-repeat: no-repeat;
  background-position: 0 90%;
  background-size: 100% .3em;
}

.Footer_container__st7Zp {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Footer_background__2A3FW {
  background-color:#FBCA61 ;
  margin-top: 3rem;
}

.Footer_subtitle__2qxIO {
  font-size: 2rem;
  font-family: 'syne', sans-serif;
}

.Footer_socials__e39nQ {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Footer_mail__ksyBb {
  color: black;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Footer_button__label__1mJaW {
  /* text-decoration: line-through solid #FBCA61; */
  font-size: 2.8rem;
  font-family: 'Syne', sans-serif;
  position: relative;
}

.Footer_button__label__1mJaW::after {
  border-bottom: 0.125em solid white;
  content: "";
  left: 0;
  margin-top: calc(0.125em / 2 * -1);
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.Footer_button__label__1mJaW:hover::after {
  right: 100%
}

.Footer_button__15E7t {
  -webkit-text-decoration: underline transparent;
          text-decoration: underline transparent;
  color: black;
  text-align: center;
  margin: 4rem;
  display: flex;
}

.Footer_mailwrap__2hfpW {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}

.Footer_img__3qdcC {
  margin: 1rem;
}

@media only screen and (min-width: 730px) {

  .Footer_title__3IB3- {
    font-size: 4rem;
  }
  
}
.Home_container__1ukFy {
  background-image: url(/assets/img/plantjes.png);
  background-repeat: no-repeat;
  background-position-x: 5rem ;
  background-position-y:top ;
  background-size: 40%;
}

@media only screen and (min-width: 730px) {

  .Home_container__1ukFy {
    background-size: 27%;
  }
  
}
