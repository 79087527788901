@import url('https://fonts.googleapis.com/css2?family=Syne:wght@700&display=swap');

.slider {
  font-family: 'syne', sans-serif;
  color: #989795;
  font-size: 1.3rem;
  float: left;
  width: 50%;
}

@keyframes marquee {
  0% { left: 0; }
  100% { left: -100%; }
}

.slider__con div {
  display: block;
  width: 200%;
  height: 30px;

  position: absolute;
  overflow: hidden;

  animation: marquee 20s linear infinite;
}

.slider__con {
  height: 20px;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

.button__label {
  position: relative;
  font-size: 2.8rem;
  font-family: 'Syne', sans-serif;
}

.button {
  text-decoration: underline transparent;
  color: black;
  text-align: center;
}

.list {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 2rem 2rem;
}

.link {
  margin: .5rem 0;
}

.button__label::after {
  border-bottom: 0.125em solid #FBCA61;
  content: "";
  left: 0;
  margin-top: calc(0.125em / 2 * -1);
  position: absolute;
  right: 0;
  top: 50%;
  transition: 0.3s;
}

.button__label:hover::after {
  right: 100%
}

.back {
  background-color: unset;
  border: unset;
  margin: 1rem 2rem;
}

.navbar {
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-width: 370px) {

  .slider {
    font-size: 1.8rem;
  }

  .back {
    margin: 1rem 4rem;
  }

  .list {
    margin: 2rem 4rem;
  }

  .slider__con {
    height: 25px;
  }
}

@media only screen and (min-width: 550px) {

  .slider {
    font-size: 2.5rem;
  }

}