.container {
  background-image: url(/assets/img/plantjes.png);
  background-repeat: no-repeat;
  background-position-x: 5rem ;
  background-position-y:top ;
  background-size: 40%;
}

@media only screen and (min-width: 730px) {

  .container {
    background-size: 27%;
  }
  
}