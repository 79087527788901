@import url('https://fonts.googleapis.com/css2?family=Syne:wght@700&display=swap');

.title {
  font-size: 3rem;
  font-family: 'syne', sans-serif;
  text-align: center;
  background-image: linear-gradient(120deg,#FBCA61,#FBCA61);
  background-repeat: no-repeat;
  background-position: 0 90%;
  background-size: 100% .3em;
}

.about__text {
  font-size: 1.5rem;
  max-width: 65rem;
  margin: 2rem 2rem;
}

.qualilty_wrap {
  margin-top: 2rem;
  opacity: 0.4;
}

.light {
  color: grey;
  text-align: center;
}

.button__devine {
  font-size: 2.8rem;
  font-family: 'Syne', sans-serif;
  position: relative;
  text-decoration: underline transparent;
  color: black;
  text-align: center;
  margin: 2rem;
  display: flex;
}

.button__devine::after {
  border-bottom: 0.125em solid white;
  content: "";
  left: 0;
  margin-top: calc(0.125em / 2 * -1);
  position: absolute;
  right: 0;
  top: 50%;
  transition: 0.3s;
}

.button__devine:hover::after {
  right: 100%
}



.button__label {
  /* text-decoration: line-through solid #FBCA61; */
  font-size: 4.8rem;
  font-family: 'Syne', sans-serif;
  position: relative;
}

.button__label::after {
  border-bottom: 0.125em solid #FBCA61;
  content: "";
  left: 0;
  margin-top: calc(0.125em / 2 * -1);
  position: absolute;
  right: 0;
  top: 50%;
  transition: 0.3s;
}

.button__label:hover::after {
  right: 100%
}

.button {
  text-decoration: underline transparent;
  color: black;
  text-align: center;
  margin: 4rem;
  display: flex;
}

.wrap__div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem 1rem;
  flex-direction: column;
}

.wrap__wrap {
  flex-direction: column-reverse;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* 
.button::before{
  content: url(/assets/img/pijlSmall.png);
  margin-right: 3rem;
  transform: rotate(90deg);
  float:left;
} */

.img {
  margin: 0 2rem;
  width: 80vw;
  height: auto;
}

@media only screen and (min-width: 450px) {

  /* .img {
    width: unset;
    height: unset;
  } */
  
}

@media only screen and (min-width: 730px) {

  .wrap__div {
    flex-direction: row;
  }

  .title {
    font-size: 4rem;
  }

  .about__text {
    font-size: 2rem;
  }

  .img {
    width: 31vw;
    height: auto;
  }

  .wrap__wrap {
    flex-direction: unset;
  }
  

}

