@import url('https://fonts.googleapis.com/css2?family=Syne:wght@700&display=swap');

.title {
  font-size: 3rem;
  font-family: 'syne', sans-serif;
  text-align: center;
  background-image: linear-gradient(120deg,#FBCA61,#FBCA61);
  background-repeat: no-repeat;
  background-position: 0 90%;
  background-size: 100% .3em;
  margin-bottom: 2rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrap__load {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading {
  font-size: 2rem;
  font-family: 'syne', sans-serif;
  text-align: center;
}

.circle {
  border: .4rem solid gainsboro;
  border-top: .4rem solid #FBCA61;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  animation: spin 2s linear infinite;
  margin: 2rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.menu {
  display: grid;
  grid-template-columns: 1fr;
}

.menu__link {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  text-decoration: none;
}

.menu__title {
  color: black;
  font-family: 'syne', sans-serif;
  font-size: 2rem;
  transition: opacity .3s;
  margin-top: 1rem;
  text-align: center;
}

.menu__tags {
  color: black;
  font-family: 'syne', sans-serif;
  font-size: 1.4rem;
  transition: opacity .3s;
  margin-bottom: 2rem;
  text-align: center;
}

.menu__img {
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  height: auto;
  width: 92vw;
  grid-row: 1;
  grid-column: 1;
}

.p {
  font-family: 'syne';
  font-size: 2rem;
  text-align: center;
  margin: 3rem;
}

.menu__item:hover .menu__title {
  opacity: 1;
}

.menu__item:hover .menu__tags {
  opacity: 1;
}

.menu__item {
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 550px) {

  .menu {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .menu__img {
    height: auto;
    width: 50vw;
  }
  
}

@media only screen and (min-width: 730px) {

  .title {
    font-size: 4rem;
  }
  
}

@media only screen and (min-width: 900px) {

  .menu__title {
  opacity: 0;
  transition: opacity .3s;
  margin: unset;
  grid-row: 1;
  grid-column: 1;
  align-self: center;
  justify-self: center;
}

.menu__tags {
  opacity: 0;
  transition: opacity .3s;
  grid-row: 1;
  grid-column: 1;
  align-self: center;
  justify-self: center;
  margin-bottom: unset;
  margin-top: 4rem;
}
  
}
